import React, { useState } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { useEffect } from "react";
import JobListings from "./views/JobListings/JobListings";
import JobApply from "./views/JobApply/JobApply";
import Login from "./views/Login/Login";
import ForCompanies from "./views/ForCompanies/ForCompanies";
import AboutUs from "./views/AboutUs/AboutUs";
import { compareSync } from "bcryptjs";
import BookMark from "./views/BookMarks/BookMark";
import FAQs from "./views/FAQs/FAQs";
import HomeNew from "./views/HomeNew";
import NotFound from "./notFound";
import EmailVerification from "./views/Signup/verifyEmail";
import Verification from "./views/Signup/Verification";
import ResetPassword from "./views/Signup/ResetPassword";
import ReleaseNotes from "./views/RealeaseNotes/ReleaseNotes";
import ForgotPassword from "./views/JobApply/modals/ForgotPassword";
import TAG from "./pages/landingpage/TAG"
import VerifyEmail from "./pages/verify-email/VerifyEmail";
import SubmitApllication from "./views/SubmitApplication/SubmitApplication";
import toast from "react-hot-toast";
import Register from "./views/Register/Register";
import SettingsModal from "./components/Modal/Modal";
import NotificationModal from "./components/Modal/NotificationModal";

const Routing = () => {

  const location = useLocation();
  const accountType = sessionStorage.getItem("account-type");
  const [listings, setListings] = React.useState([]);
  const [openModal, setOpenModal] = useState(true)
  const user = JSON.parse(sessionStorage.getItem('user'))

  const redirect = () => {
    const hostname = window.location.hostname;
    sessionStorage.setItem('has-exam', 0)
    sessionStorage.setItem('redirected', 1)
    document.querySelector('#nofication-modal').style.display = 'none'
      if (
        hostname.includes("playpen") || hostname.includes("localhost") 
      ){
        window.open(`http://playpencandiddate.testassessify.com/redirect?token=${
          user?.token
        }&name=${user?.user?.name}&id=${user?.user?.user_id}&email=${user?.user?.email}`, '_blank')
      }
      else {
        window.open(`https://candidate.testassessify.com/redirect?token=${
          user?.token
        }&name=${user?.user?.name}&id=${user?.user?.user_id}&email=${user?.user?.email}`, '_blank')
      }
  }

  useEffect(() => {
   if(!sessionStorage.getItem('redirected')){
     if(parseInt(sessionStorage.getItem('has-exam'))){
        document.querySelector('#nofication-modal').style.display = 'block'
     }
   }
    window.scrollTo({ top: 0, behavior: "instant" });
    if (accountType) {
      //compare hashed admin role string
      if (
        !compareSync("candidate", accountType) &&
        !compareSync("admin", accountType)
      ) {
        sessionStorage.setItem("is-authenticated", "0");
      }
    }
  }, [location.pathname]);

  return (
    <React.Fragment>
       {
         <NotificationModal> 
         <section>
            <h2 className="bi bi-exclamation-triangle  text-5xl md:text-6xl mb-3  text-center" />
            <h5 className="text-center text-slate-950 text-lg"> Hello, {user?.user?.name} </h5>
            <h5 className="text-center text-slate-950 font-light text-sm"> 
              You have {sessionStorage.getItem('has-exam')} pending exams to take
            </h5>
            <div className="flex justify-center mb-5">
              <button
               className="py-2 text-sm font-light mt-3 px-12 text-white bg-primary rounded-lg"
               onClick={redirect}
               >
                  Click to view my exams
              </button>
            </div>
         </section>
        </NotificationModal>
       }
    <Routes>
      <Route exact path="/" element={<HomeNew />} />
      <Route exact path="/tap" element={<TAG />} />
      <Route exact path="/tap/verify-email" element={<VerifyEmail />} />
      <Route
        path="/job-listings"
        element={<JobListings listings={listings} setListings={setListings} />}
      />
      <Route
        path="job-listings/apply/:id"
        element={<JobApply listings={listings} setListings={setListings} />}
      />
      <Route
        path="/job-listings/submit-application/:id"
        element={
          (accountType && compareSync("candidate", accountType)) ? ( <SubmitApllication /> ) 
           : <Navigate to={"/login"} />
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/for-companies" element={<ForCompanies />} />
      <Route path="/email-verification" element={<EmailVerification />} />
      <Route path="/verification" element={<Verification />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/frequently-asked-questions" element={<FAQs />} />
      <Route
        path="/bookmarks"
        element={
          accountType ? (
            compareSync("candidate", accountType) ? (
              <BookMark />
            ) : (
              <Navigate to={"/login"} />
            )
          ) : null
        }
      />
      <Route path="/LR_release_note" element={<ReleaseNotes />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
    </React.Fragment>
  );
};
export default Routing;
