import React, { useState } from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";
import BioData from "../../Profile/Biodata/BioData";
import Education from "../../Profile/Education/Education";
import Experience from "../../Profile/Experience/Experience";
import Nysc from "../../Profile/Nysc/Nysc";
import { api } from "../../../api";
import { useQuery, useMutation } from "react-query";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import UserProfileProgress from "../../Profile/UserProfileProgress";
import ReactGA from "react-ga";
import { useCustomToast } from "../../../components/customtoast/CustomToastProvider";
import { profile } from "../../../api/profile";
import { listings } from "../../../api/listings";

const UpdateProfile = ({ setScreenId, handleModal }) => {

  const navigate = useNavigate();
  const { uid1, uid2 } = useParams();
  const location = useLocation();
  const currentRoute = location.pathname;
  const [searchParams] = useSearchParams();
  const affiliate_id = searchParams.get("affiliate-id");

  const customToast = useCustomToast();

  const user = JSON.parse(sessionStorage.getItem("user"));
  const isApply = sessionStorage.getItem("noapply");

  const application_data = JSON.parse(
    sessionStorage.getItem("application_data")
  );

  const [haveExperience, setHaveExperience] = useState(false);
  const [noExperience, setNoExperience] = useState(false);
  const [haveDoneNysc, setHaveDoneNysc] = useState(false);
  const [noNysc, setNoNysc] = useState(false);
  const [openVericationModal, setOpenVericationModal] = useState(false);
  const [profileStatus, setProfileStatus] = useState(0);

  const { data: experiences, refetch } = useQuery(
    ["get-experiences"],
    () => {
      return profile.getUserExperienceListv2({userID: user?.user?.user_id});
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
      // select: (data) => data?.data,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: biodata,
    isLoading,
    isError,
    error,
    refetch: refetchBiodata,
  } = useQuery(
    ["biodata"],
    () => {
      return profile.getUserBiodatav2({ userID: user?.user?.user_id });
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
      // select: (data) => data?.data,
      refetchOnWindowFocus: false,
    }
  );

  const { data: nyscData, refetch: refetchNysc } = useQuery(
    ["get-nysc-data"],
    () => {
      return profile.getNyscv2({userID: user?.user?.user_id});
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
      // select: (data) => data?.data,
      refetchOnWindowFocus: false,
    }
  );

  const { data, refetch: refetchStatus } = useQuery(
    ["get-status"],
    async () => {
      const res = await profile.getProfileStatusv2(user?.user?.user_id);
      setProfileStatus(res.data);
      return res;
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
      // select: (data) => data?.data,
      refetchOnWindowFocus: false,
    }
  );

  const submitAffiliateTagMutation = useMutation(api.trackUpdate, {
    onSuccess: (data) => {
      console.log(data, "success");
    },
  });

  const handleHaveExperience = () => {
    setHaveExperience(!haveExperience);
    setNoExperience(false);
  };

  const handleNoExperience = () => {
    setNoExperience(!noExperience);
    setHaveExperience(false);
  };
  const handleHaveDoneNysc = () => {
    setHaveDoneNysc(!haveDoneNysc);
    setNoNysc(false);
  };

  const handleNoNysc = () => {
    setNoNysc(!noNysc);
    setHaveDoneNysc(false);
  };

  const applyForRoleMutation = useMutation(listings.ApplyForJobv2, {
    onSuccess: (data) => {
      toast.remove();
      if (data?.data?.application_status === "qualified") {
        const hostname = window.location.hostname;
        const user = JSON.parse(sessionStorage.getItem('user'))
          if (
            hostname.includes("playpen") || hostname.includes("localhost") 
          ){
            window.open(`http://playpencandiddate.testassessify.com/redirect?token=${
              user?.token
            }&name=${user?.user?.name}&id=${user?.user?.user_id}&email=${user?.user?.email}`, '_blank')
          }
          else {
            window.open(`https://candidate.testassessify.com/redirect?token=${
              user?.token
            }&name=${user?.user?.name}&id=${user?.user?.user_id}&email=${user?.user?.email}`, '_blank')
          }
      }
      else customToast.feedback(data?.message);    
      //if(affiliate_id){
      submitAffiliateTagMutation.mutate({
        email: sessionStorage.getItem("login_email"),
        latest_page: "submit-application",
        tag: sessionStorage.getItem("visitor-fingerprint"),
      });
      // }
      setTimeout(() => {
        handleModal();
        navigate("/job-listings?feedback-status=true");
      }, 100);
    },
    onError: (error) => {
      toast.remove();
      customToast.error(
        typeof error?.response?.data?.message !== "string"
          ? "Error! An Unknown Error Occured."
          : error?.response?.data?.message
      );
    },
    onMutate: () => {
      toast.loading("loading...");
    },
  });

  const validatePhoneNumber = (value) => {
    if (value === "") return;
    else {
      const phoneNumberLength = value?.replace(/\D/g, "").length;
      const countryCode = value?.startsWith("+234") ? "NG" : "Other";

      if (countryCode === "NG" && phoneNumberLength < 11) {
        return;
      } else if (countryCode !== "NG" && phoneNumberLength < 6) {
        return;
      }
      return true;
    }
  };

  const handleApplyBeta = () => {
    if (validatePhoneNumber(biodata?.data?.phone_number)) {
      const profile_percent = parseInt(
        profileStatus?.profile_completion_percentage
      );
      if (profile_percent < 70) {
        return customToast.error("Update your profile before proceeding...");
      }
      ReactGA.event({
        category: "User",
        action: "Job Application",
        label: "User Applied for Job",
      });
      applyForRoleMutation.mutate({ ...application_data });
    } else {
      toast.error(
        "Error! Invalid phone number, update you phone before you proceed"
      );
      setScreenId(3);
    }
  };

  return (
    <div
      className="rounded-lg w-full mx-auto p-3 overflow-y-scroll md:overflow-x-hidden max-h-[95vh] md:max-h-[98vh] bg-white"
      style={{ maxWidth: "98%" }}
    >
      <div className="w-full flex items-center justify-end">
        <button onClick={handleModal}>
          <FontAwesomeIcon className="text-xl" icon={faXmark} />
        </button>
      </div>
      <div className="w-full md:flex gap-x-10">
        <div className="w-full md:w-2/5 relative max-w-xs p-6 overflow-hidden bg-[#F2F3F7]  rounded-xl h-fit mb-10 md:mb-0 ">
          <UserProfileProgress profileStatus={profileStatus} />
        </div>
        <div className="w-full md:w-3/5">
          <div className="">
            <div className="">
              <BioData
                refetchStatus={refetchStatus}
                isLoading={isLoading}
                refetch={refetchBiodata}
                isError={isError}
                error={error}
                biodata={biodata}
              />
            </div>
            <Education
              refetchStatus={refetchStatus}
              // educations={educations}
              // refetchEducation={refetchEducation}
              // key={educations}
            />
            {/* <FileUpload /> */}
            {experiences?.data?.length === 0 ? (
              <div className="my-3">
                <div className=" flex items-center gap-x-4 mb-10">
                  <div className="w-1/3">
                    <p className="text-sm font-medium">
                      Do you have any work Experience?
                    </p>
                  </div>
                  <div className="w-2/3 flex gap-x-6 items-center">
                    {" "}
                    <div className="">
                      <label
                        htmlFor="experience"
                        className="inline-flex items-center"
                      >
                        <input
                          type="checkbox"
                          id="experience"
                          checked={haveExperience}
                          onChange={handleHaveExperience}
                          className="form-checkbox h-5 w-5 text-armyGreen"
                        />
                        <span className="ml-2 text-sm">Yes</span>
                      </label>
                    </div>
                    <div className="">
                      <label
                        htmlFor="no-experience"
                        className="inline-flex items-center"
                      >
                        <input
                          type="checkbox"
                          id="no-experience"
                          checked={noExperience}
                          onChange={handleNoExperience}
                          className="form-checkbox h-5 w-5 text-armyGreen"
                        />
                        <span className="ml-2 text-sm">No</span>
                      </label>
                    </div>
                  </div>
                </div>
                {haveExperience && (
                  <Experience
                    experiences={experiences}
                    refetchStatus={refetchStatus}
                    refetch={refetch}
                    key={experiences}
                  />
                )}
                {noExperience && (
                  <div className="text-armyGreen text-center text-sm">
                    {" "}
                    Continue your application
                  </div>
                )}
              </div>
            ) : (
              <Experience
                experiences={experiences}
                refetch={refetch}
                refetchStatus={refetchStatus}
                key={experiences}
              />
            )}

            <div className="my-3">
              {nyscData?.data === null ? (
                <div>
                  <div className="flex items-center gap-x-4 mb-10">
                    <div className="w-1/3">
                      {" "}
                      <p className="text-sm font-medium">
                        Are you done with Nysc?
                      </p>
                    </div>
                    <div className="w-2/3 flex gap-x-6 items-center">
                      <div className="">
                        <label
                          htmlFor="experience"
                          className="inline-flex items-center"
                        >
                          <input
                            type="checkbox"
                            id="experience"
                            checked={haveDoneNysc}
                            onChange={handleHaveDoneNysc}
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          <span className="ml-2 text-sm">Yes</span>
                        </label>
                      </div>
                      <div className="">
                        <label
                          htmlFor="no-experience"
                          className="inline-flex items-center"
                        >
                          <input
                            type="checkbox"
                            id="no-experience"
                            checked={noNysc}
                            onChange={handleNoNysc}
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          <span className="ml-2 text-sm">No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {haveDoneNysc && (
                    <Nysc
                      key={nyscData}
                      nyscData={nyscData}
                      refetchNysc={refetchNysc}
                      refetchStatus={refetchStatus}
                    />
                  )}
                  {noNysc && (
                    <div className="text-armyGreen text-center text-sm">
                      {" "}
                      Continue your application
                    </div>
                  )}
                </div>
              ) : (
                <Nysc
                  nyscData={nyscData}
                  refetchNysc={refetchNysc}
                  refetchStatus={refetchStatus}
                  key={nyscData}
                />
              )}
            </div>
            <div>
              <div className="flex justify-center">
                <button
                  onClick={handleApplyBeta}
                  className="bg-armyGreen text-white px-4 py-2 font-medium text-xl rounded-lg"
                >
                  Submit Application
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateProfile;
